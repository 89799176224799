import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";
/** video banner **/
// The video banner on the manufacturing page appears to use tubular, and I don't think these are used anywhere
// import "video.js";
// import "videojs-youtube";
/** swiper slider **/
import Swiper from "swiper/swiper-bundle.js";
/* selectric - select restructure + style */
import selectric from "selectric";
/* form validation */
import validate from "jquery-validation";
import Splide from "@splidejs/splide";
import GLightbox from "glightbox";
// new Splide( ".splide" ).mount();

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");

window.onload = function() {
	new FloatLabels(".js-float-wrap");
	setupMegaNav();
	setSwiper();
	smoothScroll();
	accordion();
	forms();
	videoBanners();
	bannerBlock();
	gallerySlider();
	contentSlider();
	setupInfoPackForm();

	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});
	/** contact **/
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
	/* product page */
	productPageSpecs();
};
export function setupInfoPackForm(){
	var infopack_form_submit = $("#infopack-form-submit");
	$("a.download").click(function(e){
		
		var form_download = $(".info-pack-modal #infopack-form-download");
		// someone might use this where infopack modal is not set, so let's check if the modal exist first
		if (form_download && form_download.length){
			$(".info-pack-modal").show();
			if(e.target.href){
				form_download[0].href = e.target.href;
				infopack_form_submit.addClass("hidden");
			}
			else{
				// this may never happen
				form_download.hide();
				$("#form-input-infoPack").val(function() {
					return this.value + " Request";
				});
				if(infopack_form_submit.hasClass("hidden")){
					infopack_form_submit.removeClass("hidden");
				}
			}
		}
		e.preventDefault();
	});
	
	$(".info-pack-modal .section-module").click(function(e){
		if(this === e.target) {
			$(".info-pack-modal").hide();
		}
	});
	$("#infopack-form-download").click(function(e){
		if(this === e.target) {
			const form = document.getElementById("infopack-form");
			if(form.checkValidity()){
				infopack_form_submit.click();
				$(".info-pack-modal").hide();
			}
			else {
				e.preventDefault();
				form.reportValidity();
			}
		}
	});
}
export function gallerySlider(){
	var elms = document.getElementsByClassName("gallery-slider");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "slide",
			rewind    : true,
			pagination: false,
			arrows    : false,
			autoplay: true,
			perPage: 5,
			gap: "1rem",
			breakpoints: {
				998: {
					perPage: 4,
				},
				768: {
					start:2,
					perPage: 2,
					padding: "20%",
				},
				576: {
					perPage: 1,
					padding: "20%",
				},
			}
		}).mount();
	}
}

export function contentSlider(){
	var elms = document.getElementsByClassName("content-slider");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "slide",
			rewind    : false,
			pagination: true,
			arrows    : false,
			autoplay: false,
		}).mount();
	}
}
export function setupMegaNav(){
	// nav search
	$(".site-header .search-drawer a").on("click", function(e) {
		e.preventDefault();
		let $this = $(this);
		let targetId = ( $this.data("target") ) ? $this.data("target") : ".h-search";

		$(targetId).slideToggle("fast");
	});

	// nav hs-links
	$(".site-header .nav-drawer a").on("click", function(e) {
		e.preventDefault();
		let $this = $(this);
		let targetId = ( $this.data("target") ) ? $this.data("target") : ".hs-links";

		$(targetId).slideToggle("fast", () => {
			$this.toggleClass("open");
		});
	});

	// mobile nav drawer
	$(".mobile-nav-drawer").on("click", function(e) {
		e.preventDefault();
		let $this = $(this);
		let mobileMenu = ( $this.data("menu") ) ? "#" + $this.data("menu") : ".mobileMenu";

		$("body").toggleClass("nav-open");

		$this.toggleClass("open");
		$(mobileMenu).toggleClass("open");

	});

	$(".mobileMenu .close-mobileMenu").on("click", function(e) {
		e.preventDefault();
		let $this = $(this);

		$("body").removeClass("nav-open");
		$this.closest(".mobileMenu").removeClass("open");
		$(".mobile-nav-drawer").removeClass("open");

	});

	$(".mbm-menus .has-sub .sub-drawer").on("click", function(e) {
		e.preventDefault();
		let $this = $(this);

		// $this.closest("li").siblings(".submenu").f
		$this.next("ul").slideToggle("fast", function() {
			$this
				.closest("li").toggleClass("open")
				.siblings("li").removeClass("open")
				.find("ul").slideUp("fast");
		});

	});

	// Megamenu Tabs
	var mgmTabs = document.querySelectorAll(".mega-menu.mgm-tab .megamenu-tab");
	if (mgmTabs.length){
		mgmTabs.forEach(function(tab){
			var tab_li = tab.querySelectorAll(".tab-menus > li");
			tab_li.forEach(function(hover_li){
				hover_li.addEventListener("mouseenter", function(li_event){
					var parent_ul = li_event.target.parentNode;
					var target_li = li_event.target;
					// update li siblings
					[...parent_ul.children].forEach(function(li){
						if (li !== target_li){
							li.classList.remove("active");
						}
					});
					/* if it's not currently active - else we're done */
					if (!target_li.classList.contains("active")){
						// activate the list item hovered
						target_li.classList.add("active");
						var contentId = target_li.getAttribute("data-content");
						var contentTarget = document.getElementById(contentId);
						if (contentTarget){
							target_li.closest(".tab-menus").classList.remove("fw");
							target_li.closest(".megamenu-tab").querySelector(".tab-contents").classList.remove("hide");
							var content_parent = contentTarget.parentNode;
							// update content div siblings
							[...content_parent.children].forEach(function(div){
								if (div !== contentTarget){
									div.classList.remove("active");
								}
							});
							// activate the corresponding content sublist
							contentTarget.classList.add("active");
						} else {
							// no sublist content for this hovered item
							target_li.closest(".tab-menus").classList.add("fw");
							target_li.closest(".megamenu-tab").find(".tab-contents").classList.add("hide");
						}
					}
				});
			});
		});
	}
}

export function setSwiper(){
	var testimonials = document.querySelectorAll(".testimonial-slider .swiper-slide");
	if (testimonials.length){
		new Swiper(".testimonial-slider", {
			loop: true,
			autoplay: { delay: 3000 },
			pagination: {
				el: ".swiper-pagination",
			},
			// Navigation arrows
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			}
		});
	}

	var fcs = document.querySelectorAll(".fcs-slider .swiper-slide");
	if (fcs.length){
		new Swiper(".fcs-slider", {
			loop: true,
			autoplay: { delay: 3000 },
			grabCursor: true,
			effect: "fade"
		});
	}

	// var product_slides = document.querySelectorAll(".product-gallery .swiper-slide");
	/*if (product_slides.length){
		// Gallery Thumb
		let galleryThumbs = new Swiper('.gallery-thumbs', {
			spaceBetween: 15,
			slidesPerView: 4,
			grabCursor: true,
			freeMode: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true
		});

		// Gallery Top
		let galleryTop = new Swiper('.gallery-top', {
			spaceBetween: 10,
			effect: 'fade',
			touchRatio: 0,
			thumbs: {
				swiper: galleryThumbs
			}
		});
	} */
}

export function smoothScroll(){
	var smooth_scrolls = document.querySelectorAll(".smooth-scroll");
	if (smooth_scrolls.length){
		smooth_scrolls.forEach(function(el){
			el.addEventListener("click", function(e){
				e.preventDefault();
				var target = e.target.getAttribute("data-href") ?? null;
				if (target){
					var target_el = document.querySelector(target);
					if (target_el){
						document.body.animate({
							scrollTop: target_el.offsetTop - 50
						}, 500);
					}
				}
			});
		});
	}
}

export function accordion(){
	let accordion = $(".accordion > .accordion-item > .header");
	accordion.on("click", function() {
		let $this = $(this);

		$this
			.closest(".accordion-item").siblings().removeClass("open")
			.find(".content").slideUp("fast");
		$this
			.closest(".accordion-item").toggleClass("open")
			.find(".content").slideToggle("fast");
		var accordion_image = $(".accordion-with-image");
		if(accordion_image){

			accordion_image.find(".image").removeClass("show");
			var image_id = $this.data("id");
			if (image_id){
				var image = $("#"+image_id);
				if (image){
					image.addClass("show");
				}
			}
		}
		
	});

	if (accordion && accordion.length > 0){
		setTimeout(() => {
			accordion[0].click();
		}, 500);
		
	}
}

export function forms(){
	$("select").each( function() {
		$(this).selectric({disableOnMobile: false, nativeOnMobile: false});
	}).on("selectric-change", function() {
		$(this).valid();
	});

	// contact template, enquiry block or infopack form
	$(".enquiryForm, .ebookForm").each(function(){
		let $form = $(this);

		// validation & submission handling
		$form.validate({
			ignore: ":not([name])",
			errorPlacement: function (err, el) {
				el.closest(".form-group").append(err);
			},
			highlight: function(element) {
				let $element = $(element),
					$group = $element.closest(".form-group");
				$group.addClass("error");
				switch (element.type) {
				case "select-one":
					if ($group.find(".selectric-wrapper").length !== 0)
						$(element).selectric("close");
					break;
				}
			},
			unhighlight: function(element) {
				var $element = $(element),
					$group = $element.closest(".form-group");
				$group.removeClass("error");
				switch (element.type) {
				case "select-one":
					if ($group.find(".selectric-wrapper").length !== 0)
						$("select").selectric("close");
					break;
				}
			},

			/*submitHandler: function(e) {
				let $this = $(e);
				let btnText = $this.find("button[type="submit"]").text();
				let action = "/" + $this.find("input[name="action"]").val();
				$this.find("button[type="submit"]").text("Sending...").prop("disabled", true);
				$.post({
					url: action,
					dataType: "json",
					data: $this.serialize(),
					success: function(res) {
						$this.find("button[type="submit"]").text( btnText ).prop("disabled", false);
						if ( res.success && res.finished && res.returnUrl ) {
							$form.trigger("reset");
							if ( $form.hasClass("ebookForm") && $form.data("ebook") ) {
								window.open($form.data("ebook"), "_blank");
								location.href = res.returnUrl ?? "/infopack-thank-you";
							}
							// console.log( res )
						}
					}
				});
			} */
		});
	});

	// news/product filters
	$(".category-select, .entry-select").selectric().on("change", function() {		
		location.href = $(this).val();
	});
}

export function videoBanners(){
	var video_wrap = $("#video-wrapper");
	if (video_wrap){
		var v_id = $(video_wrap).data("video-id");
		if (v_id){
			$(video_wrap).tubular({videoId: v_id, wrapperZIndex: 0});
		}
	}
}

export function bannerBlock() {
	// aka slidingDoors
	$(".banner-block .banner-image").on("mouseenter", function(){
		$(this).closest(".banners").find(".banner-image").removeClass("active");
		$(this).addClass("active");
	});
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});

class productPageSpecManager {
	// Construct a new productPageSpecManager for a given element
	constructor(el) {
		this.specs = [...el.querySelectorAll("section")];
		// Hide all but the first spec element
		this.specs.forEach((spec, i) => {
			if (i !== 0) {
				spec.classList.add("hidden");
			}
			$(spec.querySelector("select")).on("selectric-change", this.onSpecChange.bind(this));
		});	
	}
		
	onSpecChange(e)	{
		const select = e.target;
		const selectedOption = select.options[select.selectedIndex];
		const selectedValue = selectedOption.value;
		const selectedSpecId = selectedValue.substring(1);
		const selectedSpec = this.specs.find((spec) => spec.id === selectedSpecId);
		if (selectedSpec) {
			this.specs.forEach((spec) => { 
				spec.classList.add("hidden");
				spec.querySelector("select").value = "";
				$(spec.querySelector("select")).selectric("refresh");
			});
			
			selectedSpec.classList.remove("hidden");
		}
	}
}
export function productPageSpecs(){
	[...document.querySelectorAll(".product-specifications")].forEach(function(el){
		new productPageSpecManager(el);
	});
}
